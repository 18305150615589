import {
  Box,
  Paper,
  Typography,
  TextField,
  Grid,
  Button,
  FormControl,
  Autocomplete,
  CircularProgress,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import { DateRangePicker, CustomProvider } from "rsuite";
import esES from "rsuite/locales/es_ES";
import "moment/locale/es";
import moment from "moment";
import { postReport } from "../../../api/report";
import { useState, useEffect, useRef } from "react";

import { CalendarToday } from "@mui/icons-material";
import PushPinIcon from "@mui/icons-material/PushPin";
import BusinessIcon from "@mui/icons-material/Business";
import { getZonal, getInmueble } from "../../../api/ediServices";
import ProgressLoading from "../../../components/progressLoading/ProgressLoading";
//import DatePicker from "react-datepicker";
import "./Reports.css";

function Reports() {
 
  const [loading, setLoading] = useState(false);
  const [loadingZonal, setLoadingZonal] = useState(false);
  const [zonales, setZonales] = useState([]);
  const [zonal, setZonal] = useState(null);
  const [loadingInmueble, setLoadingInmueble] = useState(false);
  const [inmuebles, setInmuebles] = useState([]);
  const [auxInmueble, setAuxInmueble] = useState([]);
  const [inmueble, setInmueble] = useState(null);
  const [init ,setInit] = useState(null);

  const [dateRange, setDateRange] = useState([null, null]);

  const handleDateRangeChange = (value) => {
    setDateRange(value || [null, null]);
  };
  const handleSubmit = async () => {
    if (dateRange[0] == null && dateRange[1] == null) {
      alert("Ingrese una fecha inicial y una fecha final");
      return;
    }

    const initString = "00:00:00.000";
    const finString = "23:59:59.999";
    let initFechaDate = moment(dateRange[0]).format("YYYY-MM-DD");
    let finFechaDate = moment(dateRange[1]).format("YYYY-MM-DD");

    if (initFechaDate > finFechaDate) {
      alert("La fecha inicial debe ser menor que la fecha final");
      return;
    }

    const usuarioSession = JSON.parse(localStorage.getItem("jsonCliente"));
    setLoading(true);
    const response = await postReport({
      FechaInicio: initFechaDate.toString() + " " + initString,
      FechaFin: finFechaDate.toString() + " " + finString,
      IdInmueble: inmueble ? inmueble.Id : null,
      IdZonal: zonal ? zonal.Id : null,
      IdCliente: usuarioSession.id,
    });

    if (response.success) {
      var blob = new Blob([base64ToArrayBuffer(response.body.fileByteArray)], {
        type: response.body.tipoArchivo,
      });
      var url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = response.body.nombreArchivo;
      document.body.appendChild(a);
      a.click();
      a.remove();

      setLoading(false);
    } else {
      alert(response.message);
      setLoading(false);
    }
  };

  function base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  const getZonalAsync = async (clientId) => {
    try {
      setLoadingZonal(true);
      const response = await getZonal(clientId);
      const List = await JSON.parse(response);

      setZonales(List);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingZonal(false);
    }
  };

  const getInmuebleAsync = async (client) => {
    try {
      setLoadingInmueble(true);
      const response = await getInmueble(client);
      const { ListaInmueble } = await JSON.parse(response);
  
  
      setInmuebles(ListaInmueble);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingInmueble(false);
    }
  };

  useEffect(() => {
    let localStorageData = JSON.parse(localStorage.getItem("jsonCliente"));

    getZonalAsync(localStorageData.id);
    getInmuebleAsync(localStorageData.id);
  }, []);

  const renderValue = (value) => {
    const [startDate, endDate] = value || [null, null];
    const start = startDate ? startDate.toLocaleDateString() : "";
    const end = endDate ? endDate.toLocaleDateString() : "";
    return `${start} - ${end}`;
  };

  return (
    <div>
      <Paper>
        <Box px={3} py={2}>
          <Typography variant="h6" align="left" margin="dense" m={5}>
            GENERAR REPORTE
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={6} md={6}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <CustomProvider locale={esES}>
                    <div style={{ display: "flex", paddingBottom: "1rem" }}>
                      <label htmlFor="date-range">
                        Seleccione el rango de fechas
                      </label>
                      &nbsp;
                      <CalendarToday />
                    </div>

                    <Box display="flex" alignItems="center" gap={1}>
                      <DateRangePicker
                        value={dateRange}
                        onChange={handleDateRangeChange}
                        format="dd-MM-yyyy"
                        size="lg"
                        placeholder="Seleccione"
                        ranges={[
                          {
                            label: "Últimos 7 días",
                            value: [
                              new Date(
                                new Date().setDate(new Date().getDate() - 6)
                              ),
                              new Date(),
                            ],
                          },
                          {
                            label: "Últimos 30 días",
                            value: [
                              new Date(
                                new Date().setDate(new Date().getDate() - 29)
                              ),
                              new Date(),
                            ],
                          },
                        ]}
                        renderValue={renderValue}
                        style={{ width: "100%" }}
                      />
                    </Box>
                  </CustomProvider>
                </Grid>

                {/* <Grid item xs={6} sm={6}>
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={moment.locale()}
              >
                <Stack spacing={3}>
                  <DatePicker
                  range
                    dateFormat="dd-MM-yyyy"
                    disableFuture
                    label="Fecha inicial"
                    openTo="year"
                    views={["year", "month", "day"]}
                    value={initFecha}
                    onChange={(newValue) => setInitFecha(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6} sm={6}>
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={moment.locale()}
              >
                <Stack spacing={3}>
                  <DatePicker
                    dateFormat="dd-MM-yyyy"
                    //disableFuture
                    label="Fecha final"
                    openTo="year"
                    views={["year", "month", "day"]}
                    value={finFecha}
                    onChange={(newValue) => setFinFecha(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid> */}
              </Grid>

              <br />

              <FormControl fullWidth sx={{ marginTop: "0.8rem" }}>
                <div style={{ display: "flex", paddingBottom: "1rem" }}>
                  <label htmlFor="date-range">Seleccione Zonal</label>
                  &nbsp;
                  <PushPinIcon />
                </div>
                <Autocomplete
                  fullWidth
                  value={zonal}
                  // options={
                  //   zonales?.map((e) =>
                  //         JSON.stringify({ label: e.Nombre, id: e.Id })
                  //       )

                  // }
                  options={zonales}
                  getOptionLabel={(option) => {
                    return option.NombreCorto;
                    // return data.label;
                  }}
                  loading={loadingZonal}
                  renderInput={(params) => (
                    <TextField
                      label="Seleccione"
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loadingZonal ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      const result = inmuebles.filter(
                        (im) => im.IdZonal === newValue.Id
                      );
                   
                        setInit(true);
                      setAuxInmueble(result);
                      setZonal(newValue);
                    }else{
                      setAuxInmueble([]);
                      setZonal(null);
                      setInmueble(null);
                      setInit(null);
                    }
                  }}
                />
              </FormControl>

              <br />
              <br />

              <FormControl fullWidth sx={{ marginTop: "0.8rem" }}>
                <div style={{ display: "flex", paddingBottom: "1rem" }}>
                  <label htmlFor="date-range">Seleccione el Inmueble</label>
                  &nbsp;
                  <BusinessIcon />
                </div>
                <Autocomplete
                  fullWidth
                  value={inmueble}
                  // options={
                  //   zonales?.map((e) =>
                  //         JSON.stringify({ label: e.Nombre, id: e.Id })
                  //       )

                  // }
                  options={auxInmueble.length===0 &&  init===null  ? inmuebles : auxInmueble}
                  getOptionLabel={(option) => {
                    return option.Nombre;
                    // return data.label;
                  }}
                  loading={loadingInmueble}
                  renderInput={(params) => {
                    return (
                      <TextField
                        label="Inmuebles"
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loadingInmueble ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    );
                  }}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      let zonal = zonales.find((z) => z.Id === newValue.IdZonal);
                       setZonal(zonal);
                      setInmueble(newValue);
                    }else{
                      setZonal(null);
                      setInmueble(null);
                    }
                  }}
                />
              </FormControl>

              {loading ? (
                <ProgressLoading />
              ) : (
                <Box mt={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={async () => await handleSubmit()}
                  >
                    Generar
                  </Button>
                </Box>
              )}
            </Grid>
            <Grid item xs={6} ms={6}>
              <div
                class="auth-form__card--logo"
                style={{ justifyContent: "center" }}
              >
                <figure>
                  <img src="/images/auth/logo-tgestiona.png" alt="Tgestiona" />
                </figure>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </div>
  );
}

export default Reports;
