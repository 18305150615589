import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TextField, Autocomplete, CircularProgress } from "@mui/material";
import { getListaGrupoMantenimientoAsync, getListaUnidadMantenimientoAsync } from "../../../../../api/auth";

import { addIdUnidadMantenimientoroup } from "../../../../../store/formatReducer/formatoReducer.reducer";

const AutoCompleteUnidadMantenimiento = ({
  indiceSection,
  indiceGroup,
  grupo,
  bolGroup
}) => {
  const dispatch = useDispatch();
  const [listamantniemitno, setlistaMantenimiento] = useState([]);

  const [loading, setLoading] = useState();

  const [loadingGrupo, setLoadingGrupo] = useState(false);

  const [listaGrupoMantenimiento, setListaGrupoMantenimiento] = useState([]);

  const [listamantniemitnoFake, setListamantniemitnoFake] = useState([]);

  const [inputValue, setInputValue] = useState(null);

  const [grupoValue, setGrupoValue] = useState(null);

  const listarUnidadMantenimiento = async () => {
    const jsonClienteId = JSON.parse(localStorage.getItem("jsonCliente"));



    setLoading(true);
    const { body } = await getListaUnidadMantenimientoAsync({
      IdCliente: jsonClienteId?.id,
    });
    if (grupo.idUnidadMantenimiento !== null) {
      let data = body.find((x) => x.id === grupo.idUnidadMantenimiento);

      setInputValue(data);
    }

    setlistaMantenimiento(body);
    setListamantniemitnoFake(body)
    setLoading(false);
  };
  useEffect(() => {
    listarUnidadMantenimiento();
    listarGrupoMantenimiento()

  }, [setlistaMantenimiento,]);


  const listarGrupoMantenimiento = async () => {
    const jsonClienteId = JSON.parse(localStorage.getItem("jsonCliente"));

    setLoadingGrupo(true)


    const { body } = await getListaGrupoMantenimientoAsync({
      IdCliente: jsonClienteId?.id,
    });

    setListaGrupoMantenimiento(body)
    // if (grupo.idUnidadMantenimiento !== null) {
    //   let data = body.find((x) => x.id === grupo.idUnidadMantenimiento);

    //   setInputValue(data);
    // }

    //setListaGrupoMantenimiento(body);

    setLoadingGrupo(false)
  };




  useEffect(() => {


  }, [grupoValue]);


  return (
    <div style={{ display: "flex" }}>
      <div className="" style={{ margin: "0.3rem" }}>
        <Autocomplete
          disablePortal
          getOptionLabel={(option) => option.nombre}
          size="small"
          value={grupoValue}
          onChange={(event, newInputValue) => {

            setGrupoValue(newInputValue);
            setInputValue(null)
            dispatch(
              addIdUnidadMantenimientoroup({
                indiceSection,
                indiceGroup: indiceGroup,
                mantenimiento: null,
              })
            );
            if (newInputValue !== null) {

              let newArray = listamantniemitno.filter(x => x.idGrupoMantenimiento === newInputValue.id)

              setListamantniemitnoFake(newArray)

            }


          }}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {" "}
              {option.nombre}{" "}
            </li>
          )}
          loading={loadingGrupo}
          options={listaGrupoMantenimiento}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField

              sx={{ maxHeight: "10rem" }}
              // error={true}

              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              label="Grupo Mantenimiento"
            />
          )}
        />
      </div>
      <div className="" style={{ margin: "0.3rem" }}>
        <Autocomplete
          disablePortal
          getOptionLabel={(option) => option.nombre}
          size="small"
          value={inputValue}
          onChange={(event, newInputValue) => {
            if (newInputValue !== null) {
              let filterGrupo = listaGrupoMantenimiento.filter(x => x.id === newInputValue.idGrupoMantenimiento)

              setGrupoValue(filterGrupo[0])
            }

            setInputValue(newInputValue);
            dispatch(
              addIdUnidadMantenimientoroup({
                indiceSection,
                indiceGroup: indiceGroup,
                mantenimiento: newInputValue,
              })
            );
          }}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {" "}
              {option.nombre}{" "}
            </li>
          )}
          loading={loading}
          options={grupoValue == null ? listamantniemitno : listamantniemitnoFake}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField

              sx={{ maxHeight: "10rem" }}
              // error={true}

              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              label="Unidad Mantenimiento"
            />
          )}
        />
      </div>
    </div>

  );
};

export default AutoCompleteUnidadMantenimiento;
