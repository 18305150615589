import { createSlice } from "@reduxjs/toolkit"; //not use

const initialState = {
  user: null,
  offlineStore: null,
  error: null,
  loading: false,
  PermiteGenerarTicketGrupo: false,
};

export const authReducer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    addUser: (state, action) => {
      state.user = action.payload;
    },
    clearUser: (state) => {
      state.user = null;
    },
    offlineState: (state, action) => {
      state.offlineStore = action.payload;
    },

    PermiteGenerarTicketGrupo: (state, action) => {
      state.PermiteGenerarTicketGrupo = action.payload;
    },
  },
});
export const { clearUser, addUser, offlineState, PermiteGenerarTicketGrupo } =
  authReducer.actions;
export default authReducer.reducer;
