import ax from "../../helpers/axiosConfig";

export const postReport = (data) => {
  return ax.post(`/api/ReporteInspeccion/ExportarInspeccion`, data);
};

export const ExportReport = (data) => {
  return ax.get(`/api/ExportTable/GenerarInforme/${data}`);
};

export const ExportInforme = (data) => {
  return ax.get(`/api/InformeInspecciones/GenerarInforme/${data}`);
};
