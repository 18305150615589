import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import {
  sendFormat,
  sendData,
  getParametros,
  updateData,
} from "../../../../../../../store/menuGroupReducer/menuGroupReducer.action";
import ElementTechnical from "../../../../../../components/elementTechnical/ElementTechnical";
import {
  updateTextObserver,
  updateDescripcion,
  clearState,
  addParametrosOffline,
  clearStateParametros,
  updateCorrectivo,
} from "../../../../../../../store/menuGroupReducer/menuGroupReducer.reducer";
import {
  TextField,
  Grid,
  Button,
  Checkbox,
  label,
  Typography,
  FormControlLabel,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Conclusion from "./conclusion/Conclusion";
import CheckCorrectivo from "./checkCorrectivo/CheckCorrectivo";

import CustomAlert from "../../../../../../../components/customAlert/CustomAlert";
import RequiereAdjuntos from "../requiereAdjuntos/Requiereadjuntos";
import ProgressLoading from "../../../../../../../components/progressLoading/ProgressLoading";
import { PostGenerateTicket } from "../../../../../../../api/ediServices";
import useNetwork from "../../../../../../../hooks/useNetwork/UseNetwork";
import { useIndexedDB } from "@slnsw/react-indexed-db";
import "./ParamsData.css";
import { reduceEachLeadingCommentRange } from "typescript";

const ParamsData = ({
  idGrupo,
  fechaGrupo,
  handleSelected,
  handleToggle,
  setAux,
}) => {
  const [open, setOpen] = useState(false);

  const [aux1, setAux1] = useState(true);
  const [loadingSend, setLoadingSend] = useState(false);
  const [offAlert, setOffAlert] = useState(false);
  const [msg, setMsg] = useState(null);
  const [auxBol, setAuxBol] = useState(false);
  const networkState = useNetwork();
  const { online } = networkState;
  const dispatch = useDispatch();
  const { loadingConfig, confingInspection, errorConfig } = useSelector(
    (stateConfig) => stateConfig.configInspection
  );
  const { idCliente } = confingInspection;
  const tickeckcampo = false;
  const {
    campo,
    loading, //not loading in ticket
    success,
    error,
    loadingParametros,
    idConfig,
    idFormato,
  } = useSelector((stateMenu) => stateMenu.menuGroup);

  const { offlineStore } = useSelector((stateAuth) => stateAuth.auth);
  const {
    add: addMenuGroups,
    getByID: getByIdMenuGroups,
    update: updateMenuGroup,
  } = useIndexedDB("menuGroups");

  const { formato } = useSelector((stateTech) => stateTech.technicalFormato);
  const handleChangeObserver = (e, indice) => {
    dispatch(
      updateTextObserver({ ...indice, textoObservacion: e.target.value })
    );
  };

  const handleChangeDescripcion = (e, indice) => {
    dispatch(
      updateDescripcion({ ...indice, recomendacionPregunta: e.target.value })
    );
  };

  if (aux1 && idGrupo) {
    // <--- This is the line that is causing the error
    setAux1(false);
    if (offlineStore === true) {
      getByIdMenuGroups(idGrupo).then((res) => {
        const { campo } = res;
        dispatch(
          addParametrosOffline({
            parametros: campo.parametros,
            conclusion: campo.conclusion,
            recomendacion: campo.recomendacion,
            solicitaTicket: campo.solicitaTicket,
          })
        );
      });
    } else {
      dispatch(getParametros({ idGrupo, fechaGrupo }));
    }
  }

  const handleClick = () => {
    let arrayFilter = [];
    let arrayFilter2 = [];

    campo?.parametros.forEach((e, index) => {
      e.subCampos.forEach((e2, index2) => {
        if (e2.fieldMandatory === true) {
          switch (e2.idParametro) {
            case 4:
              if (e2.fieldValue === "") {
                arrayFilter2.push(
                  e2.fieldLabel === ""
                    ? `subPosición de ${index + 1} en ${
                        index2 + 1
                      } : es necesario adjuntar archivo`
                    : ` ${e2.fieldLabel} : es necesario adjuntar archivo `
                );
              }
              break;

            case 6:
              if (e2.fieldValue === "") {
                arrayFilter2.push(
                  e2.fieldLabel === ""
                    ? `subPosición de ${index + 1} en ${
                        index2 + 1
                      } :  es necesario adjuntar imagen `
                    : `${e2.fieldLabel} : es necesario adjuntar imagen  `
                );
              }
              break;
            case 13:
              if (e2.fieldValue === "") {
                arrayFilter2.push(
                  e2.fieldLabel === ""
                    ? `${index + 1} en ${
                        index2 + 1
                      } : es necesario adjuntar imagenes`
                    : `${e2.fieldLabel} : es necesario adjuntar imagenes`
                );
              }
              break;
            case 14:
              if (e2.fieldValue === "") {
                arrayFilter2.push(
                  e2.fieldLabel === ""
                    ? ` ${index + 1} en ${
                        index2 + 1
                      } : es necesario adjuntar archivos`
                    : `${e2.fieldLabel} : es necesario adjuntar archivos`
                );
              }
              break;

            default:
              if (e2.fieldValue === "") {
                arrayFilter2.push(
                  e2.fieldLabel === "" || e2.fieldLabel === null
                    ? `subPosición de ${index + 1} en ${
                        index2 + 1
                      } : este campo es obligatorio`
                    : `${e2.fieldLabel} : este campo es obligatorio `
                );
              }

              break;
          }
        }
      });

      if (e.fieldMandatory === true) {
        switch (e.idParametro) {
          case 4:
            if (e.fieldValue === "") {
              arrayFilter.push(
                e.fieldLabel === ""
                  ? `posición ${index + 1} : es necesario adjuntar archivo`
                  : `${e.fieldLabel} : es necesario adjuntar archivo`
              );
            }
            break;
          case 6:
            if (e.fieldValue === "") {
              arrayFilter.push(
                e.fieldLabel === ""
                  ? `posición ${index + 1} : es necesario adjuntar archivo`
                  : `${e.fieldLabel} : es necesario adjuntar archivo`
              );
            }
            break;
          case 13:
            if (e.fieldValue === "") {
              arrayFilter.push(
                e.fieldLabel === ""
                  ? `posición ${index + 1} :  es necesario adjuntar imagenes `
                  : `${e.fieldLabel} : es necesario adjuntar imagenes `
              );
            }
            break;
          case 14:
            if (e.fieldValue === "") {
              arrayFilter.push(
                e.fieldLabel === ""
                  ? `posición ${index + 1} :  es necesario adjuntar archivos `
                  : `${e.fieldLabel} : es necesario adjuntar archivos `
              );
            }
            break;

          default:
            if (e.fieldValue === "") {
              arrayFilter.push(
                e.fieldLabel === "" || e.fieldLabel === null
                  ? `posición ${index + 1} : este campo es obligatorio`
                  : `${e.fieldLabel} : este campo es obligatorio`
              );
            }
        }
      }
    });

    arrayFilter = [...arrayFilter, ...arrayFilter2];

    if (arrayFilter.length >= 1) {
      const titulo = "⚠️ Estos campos son obligatorios:\n";
      const separador = "------------------------\n";
      const mensajeCompleto =
        titulo +
        separador +
        arrayFilter.map((msg, index) => `• ${msg}`).join("\n");

      window.alert(mensajeCompleto);

      return;
    }

    if (offlineStore === true) {
      if (
        campo.solicitaTicket &&
        (campo.recomendacion === undefined ||
          campo.recomendacion?.trim().length === 0 ||
          campo.recomendacion === null)
      ) {
        window.alert("Debe ingresar una recomendación");
        return;
      }

      addMenuGroups({
        campo: {
          ...campo,
          idFormato: idFormato,
          idConfig,
          ticket: null,
        },
        registro: new Date().toUTCString(),
        idConfig,
      })
        .then(() => {
          setAux(false);
          let newArray;
          formato.secciones.forEach((e) => {
            if (e.nombre === campo.nombreSeccion) {
              return e.grupos.forEach((e) => {
                if (e.nombre === campo.nombreGrupo) {
                  newArray = e.parametros;
                }
              });
            }
          });

          dispatch(clearStateParametros(newArray));
        })
        .finally(() => {
          setOffAlert(true);
        });

      //llenar tictekt
    } else {
      if (campo.solicitaTicket) {
        console.log("Entro");
        setLoadingSend(true);
        if (
          campo.solicitaTicket &&
          (campo.recomendacion === undefined ||
            campo.recomendacion?.trim().length === 0 ||
            campo.recomendacion === null)
        ) {
          window.alert("Debe ingresar una Conclusión");
          setLoadingSend(false);
          return;
        }

        PostGenerateTicket({
          IdCliente: idCliente,
          IdInmueble: campo.ubigeoIndex.IdInmueble,
          IdEdificio: campo.ubigeoIndex.IdEdificio,
          IdNivel: campo.ubigeoIndex.IdNivel,
          Conclusion: campo.recomendacion,
          Codigo: confingInspection.codigo + "-" + confingInspection.id,
          plataforma: 1,
        })
          .then((res) => {
            if (res.TipoResultado === 1) {
              setMsg(res.Mensaje);
              Promise.resolve(
                dispatch(sendFormat()).then(() => {
                  dispatch(sendData({ ticket: res.Mensaje }));
                  setLoadingSend(false);
                  return;
                })
              );
            }
            if (res.TipoResultado === 2) {
              setLoadingSend(false);
              setAuxBol(true);
              setMsg(res.Mensaje);
            }
          })
          .catch((err) => {
            setLoadingSend(false);
            setAuxBol(true);
            setMsg(err);
          });
      }

      if (!campo.solicitaTicket) {
        setLoadingSend(true);
        setMsg(null);
        Promise.resolve(
          dispatch(sendFormat())
            .then((e) => {
              if (e.error) {
                alert("Error al enviar el formato");
                setLoadingSend(false);
                return;
              }
              dispatch(sendData({ ticket: null }));
              setLoadingSend(false);
              return;
            })
            .catch((e) => {
              alert("Error al enviar Archivos");
              setLoadingSend(false);
              console.log(e);
            })
        );
      }
    }
  };
  const handleUpdate = () => {
    if (offlineStore === true) {
      if (
        campo.solicitaTicket &&
        (campo.recomendacion === undefined ||
          campo.recomendacion?.trim().length === 0 ||
          campo.recomendacion === null)
      ) {
        window.alert("Debe ingresar una recomendación");
        return;
      }

      getByIdMenuGroups(idGrupo)
        .then((res) => {
          const { campo: campoMenu, id, idConfig, registro } = res;

          campoMenu.parametros = campo.parametros;
          updateMenuGroup({
            id: id,
            campo: {
              ...campoMenu,
              conclusion: campo.conclusion,
              recomendacion: campo.recomendacion,
              solicitaTicket: campo.solicitaTicket,
            },
            idConfig: idConfig,
            registro,
          });
        })
        .then(() => {
          setAux(false);
          let newArray;
          formato.secciones.forEach((e) => {
            if (e.nombre === campo.nombreSeccion) {
              return e.grupos.forEach((e) => {
                if (e.nombre === campo.nombreGrupo) {
                  newArray = e.parametros;
                }
              });
            }
          });

          dispatch(clearStateParametros(newArray));

          /*dispatch(
            clearStateParametros(
              paramsReset.filter((notUndefined) => notUndefined !== undefined)
            )
          );*/
          // MODIFICADO 2:09AM
        })
        .finally(() => {
          setOffAlert(true);
        })
        .catch((e) => {
          alert("Error al enviar Archivos ");
          setLoadingSend(false);
          console.log(e);
        });
    } else {
      if (
        campo.solicitaTicket &&
        (campo.recomendacion === undefined ||
          campo.recomendacion?.trim().length === 0 ||
          campo.recomendacion === null)
      ) {
        window.alert("Debe ingresar una Conclusión");
        return;
      }

      if (campo?.parametros[0]?.ticket) {
        Promise.resolve(
          dispatch(sendFormat()).then(() =>
            dispatch(updateData({ idGrupo, ticket: null }))
          )
        );
      }
      if (!campo?.parametros[0]?.ticket && campo.solicitaTicket) {
        //
        PostGenerateTicket({
          IdCliente: idCliente,
          IdInmueble: campo.parametros[0].idInmueble,
          IdEdificio: campo.parametros[0].idEdificio,
          IdNivel: campo.parametros[0].idNivel,
          Conclusion: campo.recomendacion,
          Codigo: confingInspection.codigo + "-" + confingInspection.id,
          plataforma: 1,
        })
          .then((res) => {
            if (res.TipoResultado === 1) {
              setMsg(res.Mensaje);
              Promise.resolve(
                dispatch(sendFormat()).then(() =>
                  dispatch(updateData({ idGrupo, ticket: res.Mensaje }))
                )
              );
            }
            if (res.TipoResultado === 2) {
              setAuxBol(true);

              setMsg(res.Mensaje);
            }
          })
          .catch((err) => {
            setAuxBol(true);
            setMsg(err);
          });
      }
      if (!campo.solicitaTicket) {
        setMsg(null);
        Promise.resolve(
          dispatch(sendFormat()).then(() =>
            dispatch(updateData({ idGrupo, ticket: null }))
          )
        );
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClose2 = () => {
    setAuxBol(false);
  };
  const handleAlerOff = () => {
    setOffAlert(false);
  };

  useEffect(() => {
    if (error) {
      setOpen(true);
      setMsg(error);
      setTimeout(() => {
        handleSelected();
        handleToggle();
        setOpen(false);
        setAux(false);
        setMsg(null);
        dispatch(clearState());
      }, 2500);
    }
    if (success) {
      setOpen(true);
      setTimeout(() => {
        // handleSelected();
        handleToggle();
        setAux(false);
        setOpen(false);
        let newArray;
        formato.secciones.forEach((e) => {
          if (e.nombre === campo.nombreSeccion) {
            return e.grupos.forEach((e) => {
              if (e.nombre === campo.nombreGrupo) {
                newArray = e.parametros;
              }
            });
          }
        });

        dispatch(clearStateParametros(newArray));
      }, 2500);
    }
  }, [loading, success, dispatch, error, online, offlineStore]);

  {
    /*  useEffect(() => {

    return () => {}
  })*/
  }
  return (
    <>
      {loadingParametros ? (
        <ProgressLoading />
      ) : (
        <div className="ParamsData-container">
          {campo?.parametros?.map((parametro, i) => {
            return (
              <>
                <Grid container spacing={3} rowSpacing={3} alignItems="center">
                  <Grid item xs={4}>
                    <ElementTechnical
                      field={parametro}
                      key={"parametro" + i}
                      indice={{
                        indiceParams: i,
                        indiceSubcampo: undefined,
                      }}
                      estado={formato?.estado}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    {parametro?.observacion && (
                      <TextField
                        variant={"outlined"}
                        size="large"
                        label="Observación"
                        margin="normal"
                        value={parametro.textoObservacion}
                        multiline
                        disabled={
                          formato?.estado === "ATENDIDA" ||
                          (formato?.estado === "EN PROGRAMACIÓN" && true)
                        }
                        onChange={(e) =>
                          handleChangeObserver(e, {
                            indiceParams: i,
                            indiceSubcampo: undefined,
                          })
                        }
                        rows={4}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    {(parametro?.requiereCorrectivo ||
                      parametro?.requiereCorrectivoPregunta) && (
                      <>
                        <div>
                          <TextField
                            variant={"outlined"}
                            size="large"
                            label="Descripción"
                            margin="normal"
                            value={parametro.recomendacionPregunta}
                            multiline
                            onChange={(e) =>
                              handleChangeDescripcion(e, {
                                indiceParams: i,
                                indiceSubcampo: undefined,
                              })
                            }
                            rows={4}
                            InputLabelProps={{ shrink: true }}
                            disabled={parametro?.ticketPregunta}
                          />
                          <>
                            {!parametro?.requiereCorrectivoPregunta ||
                            parametro?.requiereCorrectivo ? (
                              <>
                                <CheckCorrectivo
                                  i={i}
                                  indiceSubcampo={undefined}
                                  textDescripcion={
                                    parametro.recomendacionPregunta
                                  }
                                ></CheckCorrectivo>
                              </>
                            ) : parametro?.ticketPregunta === null ? null : (
                              <Typography
                                variant="h6"
                                component="div"
                                sx={{
                                  padding: "1rem",
                                  textAlign: "left",
                                  fontWeight: "bold",
                                }}
                              >
                                Ticket : {parametro?.ticketPregunta}
                              </Typography>
                            )}
                          </>
                        </div>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    {(parametro?.requiereAdjuntos ||
                      parametro?.requiereAdjuntosPregunta) && (
                      <RequiereAdjuntos
                        field={parametro}
                        key={"parametro" + i}
                        indice={{
                          indiceParams: i,
                          indiceSubcampo: undefined,
                        }}
                      ></RequiereAdjuntos>
                    )}
                  </Grid>
                </Grid>
                {
                  <div className="subcampo">
                    <Grid container spacing={4}>
                      {parametro.subCampos.length ? (
                        parametro.subCampos.map((elemento, indiceSubcampo) => (
                          <>
                            <Grid item xs={4}>
                              <ElementTechnical
                                field={elemento}
                                key={"parametro" + i}
                                indice={{
                                  indiceParams: i,
                                  indiceSubcampo: indiceSubcampo,
                                }}
                                estado={formato?.estado}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              {elemento?.observacion && (
                                <TextField
                                  variant={"outlined"}
                                  size="large"
                                  label="Observación"
                                  value={elemento.textoObservacion}
                                  margin="normal"
                                  multiline
                                  disabled={
                                    formato?.estado === "ATENDIDA" ||
                                    (formato?.estado === "EN PROGRAMACIÓN" &&
                                      true)
                                  }
                                  onChange={(e) =>
                                    handleChangeObserver(e, {
                                      indiceParams: i,
                                      indiceSubcampo: indiceSubcampo,
                                    })
                                  }
                                  rows={4}
                                  InputLabelProps={{ shrink: true }}
                                />
                              )}
                            </Grid>
                            <Grid item xs={3}>
                              {(elemento?.requiereCorrectivo ||
                                elemento?.requiereCorrectivoPregunta) && (
                                <>
                                  <div>
                                    <TextField
                                      variant={"outlined"}
                                      size="large"
                                      label="Descripción"
                                      margin="normal"
                                      value={elemento.recomendacionPregunta}
                                      multiline
                                      onChange={(e) =>
                                        handleChangeDescripcion(e, {
                                          indiceParams: i,
                                          indiceSubcampo: indiceSubcampo,
                                        })
                                      }
                                      rows={4}
                                      InputLabelProps={{ shrink: true }}
                                      disabled={elemento?.ticketPregunta}
                                    />
                                    <>
                                      {!elemento?.requiereCorrectivoPregunta ||
                                      elemento?.requiereCorrectivo ? (
                                        <>
                                          <CheckCorrectivo
                                            i={i}
                                            indiceSubcampo={indiceSubcampo}
                                            textDescripcion={
                                              elemento.recomendacionPregunta
                                            }
                                          ></CheckCorrectivo>
                                        </>
                                      ) : elemento?.ticketPregunta ===
                                        null ? null : (
                                        <Typography
                                          variant="h6"
                                          component="div"
                                          sx={{
                                            padding: "1rem",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Ticket : {elemento?.ticketPregunta}
                                        </Typography>
                                      )}
                                    </>
                                  </div>
                                </>
                              )}
                            </Grid>
                            <Grid item xs={4}>
                              {(elemento?.requiereAdjuntos ||
                                elemento?.requiereAdjuntosPregunta) && (
                                <RequiereAdjuntos
                                  field={elemento}
                                  key={"parametro" + i}
                                  indice={{
                                    indiceParams: i,
                                    indiceSubcampo: indiceSubcampo,
                                  }}
                                ></RequiereAdjuntos>
                              )}
                            </Grid>
                          </>
                        ))
                      ) : (
                        <div></div>
                      )}
                    </Grid>
                  </div>
                }
              </>
            );
          })}
          <Conclusion estado={formato?.estado} idGrupo={idGrupo} />
          {!idGrupo && (
            <LoadingButton
              loading={loadingSend}
              sx={{ marginTop: "2rem", mr: 2 }}
              color="primary"
              variant="contained"
              onClick={handleClick}
              disabled={formato?.fechaInicioReal ? false : true}
              className="ParamsData-button"
            >
              Crear
            </LoadingButton>

            /*
            <Button
              variant="contained"
              sx={{ marginTop: "2rem" }}
              color="primary"
              onClick={handleClick}
                disabled={formato?.fechaInicioReal ? false : true}
                className="ParamsData-button"
            >
              Crear
            </Button>*/
          )}
          {idGrupo && formato?.estado !== "ATENDIDA" && (
            <LoadingButton
              loading={loading}
              sx={{ marginTop: "2rem", mr: 2 }}
              color="primary"
              variant="contained"
              onClick={handleUpdate}
              disabled={formato?.fechaInicioReal ? false : true}
              className="ParamsData-button"
            >
              Actualizar
            </LoadingButton>
            /*     <Button
              variant="contained"
              sx={{ marginTop: "2rem" }}
              color="primary"
                onClick={handleUpdate}
                className="ParamsData-button"
            >
              Actualizar
            </Button>*/
          )}
          {open &&
            (success ? (
              <CustomAlert
                tipe={"success"}
                message={`Acción  realizada correctamente, ${msg ? msg : ""}`}
                handleClose={handleClose}
              />
            ) : (
              <CustomAlert
                tipe={"error"}
                message={`Ocurrio un Error, ${msg ? msg : ""}`}
                handleClose={handleClose}
              />
            ))}

          {auxBol && (
            <CustomAlert
              tipe={"error"}
              message={msg}
              handleClose={handleClose2}
            />
          )}
          {offAlert && (
            <CustomAlert
              tipe={"success"}
              message={"Acción realizada correctamente"}
              handleClose={handleAlerOff}
            />
          )}
          {/* <CustomProgress open={loading} /> */}
        </div>
      )}
    </>
  );
};

export default ParamsData;
